<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img cover-background"
      data-overlay-dark="3" style="background-image: url('/img/treeremovalbanner.jpg')"
       >
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>TREE REMOVAL</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
          <h4>Tree Removal Services</h4>

             <ul class="list-style-4">
                      <li>Stump Removal</li>
                      <li>Branch and Tree Removal</li>
                      <li>Mulching</li>
                      <li>Land Clearing</li>
                      <li>Firewood</li>

              </ul>

               <img src="/img/treeremoval1.jpg" class="img-fluid">
               <div  style="margin-bottom:20px;"><b>Remove damaged, dead or unwanted trees</b></div>

               <img src="/img/treeremoval2.jpg" class="img-fluid">
               <b>Have your trees inspected before it's to late</b>
         </div>

         <div class="col-7">
           <h6>Dead tree removal</h6>
           <p>A dead tree is extremely attractive to pests.
              Carpenter ants, termites, and other wood-boring insects will can
              infest Other Healthy Trees. Removal is necessary because a dead
              tree will slowly lose strength. As the tree weakens, it loses
              its ability to withstand the assault of storms or extra weight.
              Snow, ice, storms, and winds can break
             off branches or bring down a dead tree.  The fall of a dead tree or
             branch can cause extensive damage to nearby houses, cars,
             or even people.  Dead trees represent a tremendous potential
             liability and should be removed as soon as reasonably possible.
             </p>

            <h6>Diseased Tree care and removal</h6>
            <p>A diseased tree needs to be addressed as soon as possible before it becomes
              a dead tree. A diseased tree can spread it's ailment to other healthy trees.
              Pest like carpenter ants, termites, and other wood-boring insects multiple and
              diseases such as oak wilt, dutch elm disease, cankers, and apple scab can
              infest other healthy trees. Diseased tree also present a safety risk.
              Unhealthy branches weaken over time and will break over time.
              </p>

            <h6>Remove damaged brees or branches</h6>
            <p>Is a damaged tree threatening to fall and compromise your
              home’s integrity? Protect your loved one’s by removing
              it before it can cause any more problems. Houston Tree
              Removal offers emergency tree removal services. When you
              have a damaged tree to contend with, our arborists will
              quickly arrive on-site and assess the situation. We’ll
              recommend the most effective tree trimming or removal
              method for your damaged or fallen tree. Once you’ve
              approved our service estimate, we’ll get to work.
              </p>

            <h6>Tree trimming and removal for Storm Preparation and Clean Up</h6>
            <p>Drastic weather changes can happen at any time.
            Storms may blow away entire trees and cause
            them to land on homes, cars, and other structures.
            Prepare your trees for the bad weather with our
            storm preparation services. Our services include
            preventive tree pruning to improve your tree’s
            wind resistance, which will lessen the chance of
            damage. We will also determine if a tree should
            be removed or not.
            </p>

            <h6>Tree removal for New Construction</h6>
            <p>Sometimes trees have to be removed for new construction.
            when planning new construction. Whether putting in new pool, planning
            a new add-on to your home or business ABC Professional Tree
            Service can remove your unwanted trees.
            </p>
          </div>
         </div>
    </div>
</section>
  </div>
</template>

<script>

export default {
  name: 'Treeremoval',
  components: {
  },

};
</script>
<style scoped>

</style>
