<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img"
      data-overlay-dark="3" style="background-image: url('/img/landclearingbanner.jpg')"
       >
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>LAND CLEARING & UNDERBRUSH REMOVAL</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
             <img src="/img/landclearingbefore.jpg" class="img-fluid">
             <h6>Before</h6>

             <img src="/img/landclearingafter.jpg" class="img-fluid">
             <h6>After</h6>
         </div>

         <div class="col-7">
           <h3>Land Clearing</h3>
           <p>Whether you're looking to clear your lot to make more space,
             expand your yard, or you're planning to build on the site,
             we have land and lot clearing services that you can depend on.
             No matter the size of your lot, we work vigorously to ensure your
             property is cleared by your deadline. Watch your land go through a
             complete transformation from an unruly vegetation to a picturesque lot.
             No matter how steep the hill or how flat the land on your property,
             we are capable of clearing land of any formation.</p>

            <h3>Underbrush Removal</h3>
            <p>Our team at ABC Residential Tree Services offers underbrush removal
              and underbrush mulching services for home and property owners in the
              Houston area. Removing underbrush is an important part of maintaining
              healthy trees. Too much underbrush can cause trees to become
              malnourished by sucking up all of the water and nutrients
              that trees need to grow. In addition to this mulching, allows your
              trees to get much-needed nutrients from the plants and underbrush that
              was suffocating your trees. We have years of experience assessing
              the cause of unhealthy trees and will take the steps necessary
              to ensure that your trees remain healthy and strong.</p>

          </div>
         </div>
    </div>
</section>
  </div>
</template>

<script>

export default {
  name: 'Treeremoval',
  components: {
  },

};
</script>
<style scoped>

</style>
