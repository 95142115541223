<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img"
      data-overlay-dark="3" style="background-image: url('/img/shrubpruningbanner.jpg')"
       >
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>SHRUB PRUNING</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
                   <img src="/img/pruning1.jpg" style="margin-bottom:30px" class="img-fluid">
             <ul class="list-style-4">
                      <li>Beautity your property</li>
                      <li>Healthier plants</li>
                      <li>Manage growth direction</li>
                      <li>Removed diseased parts of the shrubs</li>
              </ul>
         </div>

         <div class="col-7">
           <h5>Shrub Pruning promotes tree health.</h5>
           <p>Shrub pruning not only makes your shrubs and
             lawn look great it is a necessary component of
             proper landscape maintenance. Shrub pruning also
             improves the health of your shrubs by removing dead
             organic material. Shrub pruning is also is great for
             removing unwanted growth so your shrub can dedicate
             energy growing in the places you want it to.
           </p>
             <p>
              Ensure the growth and long-term health of your
              shrubs with ABC Professional Tree Services.
            </p>

            <div style="margin-top:70px; margin-bottom:20px;" >
            <h5>Types of pruning</h5>
            </div>

             <b>Reduction pruning</b>
            <p>Reduction pruning removes live branches to reduce
              of the overall size of the plant.
            </p>

             <b>Structural pruning</b>
            <p>Structure pruning of young trees involves removal
              of live plant parts to improve branching orientation,
              spacing, or growth rate.
            </p>

             <b>Restoration pruning</b>
            <p>Restoration pruning removes live or dead tree parts
              to restore a tree or shrub to its natural form, growth
              habit, or vigor. Restoration pruning can be performed
              to minimize health and structural integrity issues
              caused by storm damage.
            </p>

             <b>Root pruning</b>
            <p>Root pruning is needed when roots
              grow into sidewalks, foundations or
              other free-standing structures..
            </p>

          </div>
         </div>
    </div>
</section>
  </div>
</template>

<script>

export default {
  name: 'Treeremoval',
  components: {
  },

};
</script>
<style scoped>

</style>
