<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img"
      data-overlay-dark="3" style="background-image: url('/img/stornprepandcleanupbanner.jpg')"
       >
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>STORM PREPARATION AND CLEAN UP</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
             <ul class="list-style-4">
                  <img src="/img/storm1.jpg" class="img-fluid">
                  <b>Remove trees from infrastructure</b>

                  <img src="/img/storm2.jpg" style="margin-top:30px" class="img-fluid">
                  <b>Remove damaged tree from your property</b>

              </ul>
         </div>

         <div class="col-7">
           <h5>Storm Preparation</h5>
           <p>
              Severe weather can strike at any time. Hurricanes,
              tornandoes and heavy rains may push a tree to its limits.
              Storms can blow away entire trees causing severe damage to
              homes, cars, and other structures. When it comes to bad weather it
              not if but when. Prepare your trees for
              the bad weather with our storm preparation services.
             </p>

            <p>
              Our services include preventive tree pruning to improve
              your tree’s wind resistance, which will lessen the
              chance of damage. In addition, we protect the tree’s
              root system with a mulch ring around its base.
              We’ll also determine if a tree should be removed or not.
            </p>

            <h5>Storm Clean Up</h5>
            <p>
                In the aftermath of a hurricane or tropical storm,
                damage is almost always a certainty.
                After every storm, our team is in the field working
                to clean up uprooted trees and debris that have
                littered the roadways, caused blockages, landed on homes, ect.
              </p>
              <p>
                If you find yourself impacted by any of the
                above mentioned damages after a storm, give
                us a call so that we can deploy our team
                to provide emergency assistance.
              </p>

          </div>
         </div>
    </div>
</section>
  </div>
</template>

<script>

export default {
  name: 'Treeremoval',
  components: {
  },

};
</script>
<style scoped>

</style>
