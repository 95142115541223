<template>
  <div class="footer">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 sm-margin-30px-bottom">
            <img alt="footer-logo" src="img/logos/logodog.png" />
            <p class="margin-20px-top">
              Safe, reliable, and cost effective vegetation
              management services.
            </p>
         <!--   <div class="margin-25px-top footer-social-icons">
              <ul>
                <li>
                  <a href="#!"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div> -->
          </div>

          <div class="col-lg-4 col-md-6 sm-margin-30px-bottom">
            <h3 class="footer-title-style2 text-theme-color">Corporate Office</h3>
            <ul class="footer-list">
              <li>
                <span class="d-inline-block vertical-align-top font-size18"
                  ><i class="fas fa-map-marker-alt text-theme-color"></i
                ></span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left"
                  >201 Flint Ridge Rd., Suite 201 <br> Webster, TX 77598 </span>
              </li>
              <li>
                <span class="d-inline-block vertical-align-top font-size18">
                  <i class="fas fa-mobile-alt text-theme-color"></i>
                </span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  281.280.1100</span>
              </li>
              <li>
                <span class="d-inline-block vertical-align-top font-size18"
                  ><i class="far fa-envelope text-theme-color"></i
                ></span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  sales@abctree.com</span>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 offset-lg-1">
            <h3 class="footer-title-style2 text-theme-color">
              Nations Largest Minority Owned
              Vegetaion Management Company</h3>
            <ul class="footer-list">
              <li>
                <span class="d-inline-block vertical-align-top font-size18">
                  <i class="fas fa-globe text-theme-color"></i>
                </span>
                <span class="d-inline-block width-85 vertical-align-top padding-10px-left">
                  www.abctree.com
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-bar xs-font-size13">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-left xs-text-center xs-margin-5px-bottom">
              <b>a division of ABC Professional Tree Service</b>
              <p>
                &copy; Copyright ABC Professional Tree Service. All Rights Reserved.
              </p>
            </div>
            <div class="col-md-6 text-right xs-text-center">
              <span style="font-size:10px;">Design and Developed by: SLFEMP</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
