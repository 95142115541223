<template>
  <div class="securitycameras">

      <!-- start page title section -->
      <section
      class="page-title-section2 bg-img"
      data-overlay-dark="3" style="background-image: url('/img/treetrimmingbanner.jpg')"
       >
          <div class="container">

         <div class="row">
                  <div class="col-md-12">
                    <div style="position:relative; top:-50px;">
                      <h1>Tree Trimming</h1>
                    </div>
                  </div>
              </div>

          </div>
      </section>

<section class="lg">
    <div class="container">
        <div class="row">

        <div class="col-5">
          <h4>Tree Trimming Services</h4>
             <ul class="list-style-4">
                      <li>Pruning</li>
                       <li>Topping</li>
                        <li>Crown Thinning</li>
              </ul>

                <img src="/img/treetrimming1.jpg" class="img-fluid">

               <img src="/img/storm3.jpg" style="margin-top:30px" class="img-fluid">

         </div>

         <div class="col-7">
           <h3>Pruning</h3>
           <p>Tree topping is a practice used with some trees to
             help to promote proper plant growth, safety and to
             maintain their beauty. Old dead branches can be a
             hazard to your home, or even injure a passerby.
             Don’t let your trees overgrow powerlines, lights,
             paths, or any other object in your landscape. By
             taking off the top of the tree, we can reduce the
             amount of shade the tree gives, providing you opportunities
             you don’t have in the shade of a large tree. Plants underneath
             your tree will begin to thrive in sunlight, lawns will flourish again.</p>

            <h3>Topping</h3>
            <p>Tree topping is a practice used with some trees to help
              to promote proper plant growth, safety and to maintain
              their beauty. Old dead branches can be a hazard to
              your home, or even injure a passerby. Don’t let your
              trees overgrow powerlines, lights, paths, or any other
              object in your landscape. By taking off the top of the
              tree, we can reduce the amount of shade the tree gives,
              providing you opportunities you don’t have in the shade of a
              large tree. Plants underneath your tree will begin to
              thrive in sunlight, lawns will flourish again.</p>

               <h3>Crowning</h3>
               <p>If you have large trees on your property,
                 you may need to begin thinning the crown or
                 canopy of the tree year after year.
                 When performed properly, thinning your
                 tree will improve its health, its beauty,
                 and it can provide you with a myriad of other
                 benefits. We have the proper experience, tools,
                 and knowledge to ensure that your tree thrives
                 for years to come.</p>

          </div>
         </div>
    </div>
</section>
  </div>
</template>

<script>

export default {
  name: 'Treeremoval',
  components: {
  },

};
</script>
<style scoped>

</style>
