<template>
  <div class="carousel">
    <!-- Start banner Section -->
    <section
      class="parallax cover-background screen-height"
      data-overlay-dark="0"
      data-background="./img/homebanner.jpg"
    >
      <div class="container height-100">
        <div class="display-table height-100 width-100">
          <div class="display-table-cell vertical-align-middle caption" >
            <div class="overflow-hidden width-80 sm-width-85 xs-width-95">
              <h1 class="font-size50 md-font-size42 sm-font-size38 font-weight-800"
            style="color:#fff" >
                   <p>RESIDENTIAL TREE SERVICES</p>
              </h1>
              <div
                class="text-black line-height-normal font-weight-400 font-size26 md-font-size22
                sm-font-size margin-20px-bottom no-letter-spacing width-90 xs-width-70"
                style="color:#888; background:#f5f5f5;   border-radius: 25px; padding:20px;"
              >
                Call us at <a style="color:#9fbc2e" href="tel:+2812801100">(281)280-1100</a>
                or use our <a href="" style="color:#9fbc2e">online form</a>
                to schedule a free consultation.
              </div>

            <!--  <div class="margin-30px-top sm-margin-25px-top">
                <a href="/about" class="butn theme"><span>Learn More</span></a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end banner Section -->
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
