<template>
  <div class="securitycameras">
    <section>
      <div class="container">

 <div class="row">
<div class="col-12"></div>

<div class="col-5">
  <div style="padding-right:40px;"><img src="img/logo.png" class="img-fluid"/></div>
</div>

<div class="col-7">
  <h4>Corporate Summary</h4>

          <p>ABC Professional Tree Services provides right of way
            vegetation management and emergency restoration
            services for Investor Owned Electric Utilities,
            Electric Membership Corporations and Municipalities.
            The company was founded in June 2002 with 7 bucket/manual
            crews for CenterPoint Energy, Houston, Texas. Since inception
            ABC has grown to approximately 1500 employees operating in 15
            states. As a Tier l Supplier we have a large diverse workforce.
            ABC's core principles are:
          </p>

            <ul>
            <li>To provide our customers a Safe & Highly Skilled workforce. </li>
            <li>To perform cost effective & reliable Vegetation Management. </li>
            <li>To utilize newer & well maintained Equipment.</li>
            <li>To maintain Financial Responsibility for our actions.</li>
            <li>To excel with Superior Community & Customer Relations.</li>
            <li>Applying these principles allow our Managers, Supervisors, & GF's
            to focus on meeting contractual obligations in a timely manner. </li>
            </ul>

          <p>We have been in business for 20 years and have
            developed long term, high value relationships
            with our customers.
          </p>

            <h4>Mission</h4>
            <p>To exceed customer expectations in all aspects
              of daily operations while providing safe,
              reliable, and cost effective vegetation management services.
          </p>

           <h4>Keys to our success</h4>
          <ul>
            <li>Requiring all employees to work safe, NO Exceptions!.</li>
            <li>Treating all customers, public and employees with respect.</li>
            <li>Aggressively working to improve work processes for field and office employees.</li>
            <li>Continually seeking ways to improve operations,
              competitive pricing and ensure work continuity.</li>
            <li>Striving to become the "first" choice for customers
              selecting vegetation management service providers.</li>
          </ul>

         </div>
        </div>
       </div>
    </section>
   </div>
</template>

<script>

export default {
  name: 'Contact',
  components: {
  },

};
</script>
<style scoped>

</style>
