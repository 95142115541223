<template>
  <div class="container">

        <div class="row" style="margin-top:60px; margin-bottom:440px;">
          <h3>Thank you! Your request has been submitted. </h3>
        </div>

  </div>
</template>

<script>

export default {
  name: 'Requestsubmit',
  data() {
    return {
    };
  },
  components: {},
  methods: {
  },
};
</script>
<style scoped></style>
